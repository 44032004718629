import { Directive, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[ngModel][appHexa]',
  providers: [NgModel]
})

@Directive({
  selector: '[appHexa]'
})
export class HexaDirective {


  regexStr = '[a-fA-F0-9]+';
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      const new_value = this.el.nativeElement.value.replace(/[^A-Fa-f ]/g, '').replace(/\s/g, '');
      this.el.nativeElement.value = new_value;
      this.ngModelChange.emit(new_value);
      event.preventDefault();
    }, 100);
  }

}
