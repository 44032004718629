import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { AplicacionesService } from 'src/app/servicios/aplicaciones.service';
import { IAplicacion } from 'src/app/modelos/aplicacion';

@Component({
  selector: 'app-crear-editar-aplicacion',
  templateUrl: './crear-editar-aplicacion.component.html',
  styleUrls: ['./crear-editar-aplicacion.component.scss']
})
export class CrearEditarAplicacionComponent implements OnInit {

  formulario: FormGroup;
  titulo: string;
  accion: string;


  constructor(
    public matDialogRef: MatDialogRef<CrearEditarAplicacionComponent>,
    private formBuilder: FormBuilder,
    private notif: NotificationsService,
    private aplicacionesService: AplicacionesService,
    @Inject(MAT_DIALOG_DATA) private data: IAplicacion,
  ) {
  }

  private createForm() {
    if (this.data) {
      this.formulario = this.formBuilder.group({
        _id: [this.data._id, Validators.required],
        nombre: [this.data.nombre, Validators.required],
      });
    } else {
      this.formulario = this.formBuilder.group({
        nombre: ['', Validators.required],
        logo: ['']
      });
    }
  }

  public enviar() {
    // this.showSpinner = true;
    const aplicacion: IAplicacion = {
      _id: this.data ? this.data._id : null,
      nombre: this.formulario.value.nombre,
    };
    if (this.data) {
      // this.clientesService.editar(cliente).subscribe(
      //   (resp) => {
      //     this.notif.success(resp.mensaje);
      //     this.dialogRef.close(true);
      //   },
      //   (error) => {
      //     this.showSpinner = false;
      //     console.error(error);
      //     this.notif.error(error.error.mensaje);
      //   }
      // );
    } else {
      this.aplicacionesService.crear(aplicacion).subscribe(
        (resp) => {
          console.log(resp);
          this.notif.success(resp.mensaje);
          this.matDialogRef.close(true);
        },
        (error) => {
          // this.showSpinner = false;
          console.error(error);
          this.notif.error(error.error.mensaje);
        }
      );
    }

  }

  ngOnInit() {
    this.createForm();
    if (this.data) {
      this.titulo = 'Editar aplicacion';
    } else {
      this.titulo = 'Crear aplicacion';
    }
  }

}
