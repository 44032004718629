import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILogin } from '../modelos/login';
import { HttpClient } from '@angular/common/http';
import { apiAuth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  login(email: string, clave: string): Observable<ILogin> {
    return this.http.post<ILogin>(apiAuth + '/auth/login', {email, clave});
  }

}
