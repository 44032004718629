import { Injectable } from '@angular/core';
import { IAplicacion } from '../modelos/aplicacion';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AplicacionesService {

  constructor(
    private http: HttpService
  ) { }

  listar(): Observable<IAplicacion[]> {
    return this.http.get('/aplicaciones');
  }

  crear(aplicacion: IAplicacion) {
    return this.http.post('/aplicaciones', aplicacion);
  }

  eliminar(id: string) {
    return this.http.del('/aplicaciones/' + id);
  }

}
