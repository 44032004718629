import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  public imagenBase64(imagen?: string) {
    if (imagen) {
      if (imagen.split(',').length === 2) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(imagen);
      } else {
        return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imagen);
      }
    }
  }

  public esHexadecimal(numeroHexadecimal) {
    return /^[0-9A-F]+$/gi.test(numeroHexadecimal);
  }
  public deveui_valido(deveui) {
    if (deveui) {
      if (this.esHexadecimal(deveui)) {
        if (deveui.length === 16) {
          return true;
        } else {
          return 'El Deveui debe contener 16 caracteres';
        }
      } else {
        return 'El Deveui contiene caracteres invalidos';
      }
    } else {
      return 'Debe ingresar un deveui';
    }
  }

}
