import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { ICliente } from 'src/app/modelos/cliente';
import { ClientesService } from 'src/app/servicios/clientes.service';
import { NotificationsService } from 'angular2-notifications';
import { FormClienteComponent } from './form-cliente/form-cliente.component';
import { FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/servicios/helper.service';
import { ObtenerKeyComponent } from './obtener-key/obtener-key.component';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})

export class ClientesComponent implements OnInit {

  displayedColumns: string[] = ['index', 'id', 'nombre', 'aplicaciones', 'logo', 'activo', 'es_admin', 'acciones'];
  dataSource: MatTableDataSource<ICliente>;
  showSpinner = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private clientesService: ClientesService,
    private notiticationsService: NotificationsService,
    public matDialog: MatDialog,
    public helper: HelperService
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  private listar() {
    this.clientesService.listarClientes().subscribe(
      (resp) => {
        console.log(resp);
        this.dataSource = new MatTableDataSource(resp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      },
      (error) => {
        console.error(error);
        this.notiticationsService.error(error.error.mensaje);
        this.showSpinner = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  desactivar(id: string) {
    alert('desactivar: ' + id);
    // todo
  }

  activar(id: string) {
    alert('activar: ' + id);
    // todo
  }
  // Crear - Modificar
  formCliente(cliente?: ICliente) {
    const config: MatDialogConfig = {
      width: '500px',
      maxWidth: '90%',
      panelClass: 'client-form-dialog',
      data: cliente ? cliente : null,
    };
    const modal = this.matDialog.open(FormClienteComponent, config);

    modal.beforeClosed().subscribe(
      (result) => {
        if (result) {
          this.showSpinner = true;
          this.listar();
        }
      }
    );
  }

  async eliminar(cliente: ICliente) {
    const confirmed = confirm('Eliminar el cliente?');
    if (confirmed) {
      this.showSpinner = true;
      this.clientesService.eliminar(cliente._id).subscribe(
        (resp) => {
          console.log(resp);
          this.notiticationsService.success(resp.mensaje);
          this.listar();
        },
        (error) => {
          console.error(error);
          this.notiticationsService.error(error.error.mensaje);
          this.showSpinner = false;
        }
      );
    }
  }

  obtenerKey(cliente: ICliente) {
    this.matDialog.open(ObtenerKeyComponent, {
      panelClass: 'client-form-dialog',
      data: {
        cliente: cliente,
      }
    });
  }

  ngOnInit() {
    this.listar();
  }

}
