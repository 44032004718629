import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { ICliente } from 'src/app/modelos/cliente';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/servicios/helper.service';
import { ClientesService } from 'src/app/servicios/clientes.service';
import { NotificationsService } from 'angular2-notifications';
import { IAplicacion } from 'src/app/modelos/aplicacion';
import { AplicacionesService } from 'src/app/servicios/aplicaciones.service';

@Component({
  selector: 'app-form-cliente',
  templateUrl: './form-cliente.component.html',
  styleUrls: ['./form-cliente.component.scss']
})
export class FormClienteComponent implements OnInit {

  aplicaciones: IAplicacion[];
  formulario: FormGroup;
  titulo: string;
  accion: string;
  showSpinner = false;


  constructor(
    private cd: ChangeDetectorRef,
    public matDialogRef: MatDialogRef<FormClienteComponent>,
    private formBuilder: FormBuilder,
    public helper: HelperService,
    public aplicacionesService: AplicacionesService,
    private notif: NotificationsService,
    private clientesService: ClientesService,
    @Inject(MAT_DIALOG_DATA) private data: ICliente,
  ) {
  }

  private createForm() {
    if (this.data) {
      const idAplicaciones = [];
      for (const aplicacion of this.data.aplicaciones) {
        console.log(aplicacion._id);
        idAplicaciones.push(aplicacion._id);
      }
      this.formulario = this.formBuilder.group({
        _id: [this.data._id, Validators.required],
        nombre: [this.data.nombre, Validators.required],
        logo: [this.data.logo],
        idAplicaciones: [idAplicaciones]
      });
    } else {
      this.formulario = this.formBuilder.group({
        nombre: ['', Validators.required],
        logo: [''],
        idAplicaciones: [[]]
      });
    }
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (!file.type.startsWith('image')) {
        this.formulario.get('logo').setErrors({
          required: true
        });
        this.cd.markForCheck();
      } else {
        const reader = new FileReader();
        reader.onload = ((e) => {
          this.formulario.get('logo').patchValue(e.target['result']);
        });
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  public enviar() {
    // this.showSpinner = true;
    const cliente: ICliente = {
      _id: this.data ? this.data._id : null,
      nombre: this.formulario.value.nombre,
      logo: this.formulario.value.logo,
      idAplicaciones: this.formulario.value.idAplicaciones,
    };
    if (this.data) {
      this.clientesService.editar(cliente).subscribe(
        (resp) => {
          this.notif.success(resp.mensaje);
          this.matDialogRef.close(true);
        },
        (error) => {
          this.showSpinner = false;
          console.error(error);
          this.notif.error(error.error.mensaje);
        }
      );
    } else {
      this.clientesService.crearCliente(cliente).subscribe(
        (resp) => {
          this.notif.success(resp.mensaje);
          this.matDialogRef.close(true);
        },
        (error) => {
          // this.showSpinner = false;
          console.error(error);
          this.notif.error(error.error.mensaje);
        }
      );
    }

  }

  private listarAplicaciones() {
    this.aplicacionesService.listar().subscribe(
      (resp) => {
        this.aplicaciones = resp;
      },
      (error) => {
        console.error(error);
        this.notif.error(error.error.mensaje);
      }
    );
  }

  ngOnInit() {
    this.createForm();
    this.listarAplicaciones();
  }

}
