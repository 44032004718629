import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { IUsuario } from '../modelos/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpService
  ) { }

  listar(): Observable<IUsuario[]> {
    return this.http.get('/usuarios');
  }

  crear(usuario: IUsuario) {
    return this.http.post('/usuarios', usuario);
  }

  editar(usuario: IUsuario) {
    return this.http.put('/usuarios/' + usuario._id, usuario);
  }

  eliminar(id: string) {
    return this.http.del('/usuarios/' + id);
  }

}
