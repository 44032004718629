import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ICliente } from 'src/app/modelos/cliente';
import { HelperService } from 'src/app/servicios/helper.service';
import { NotificationsService } from 'angular2-notifications';
import { ClientesService } from 'src/app/servicios/clientes.service';

@Component({
  selector: 'app-obtener-key',
  templateUrl: './obtener-key.component.html',
  styleUrls: ['./obtener-key.component.scss']
})
export class ObtenerKeyComponent implements OnInit {

  showSpinner = false;
  cliente: ICliente;
  deveui: string;
  key: string;

  constructor(
    public matDialogRef: MatDialogRef<ObtenerKeyComponent>,
    private helper: HelperService,
    private notificationsService: NotificationsService,
    private clientesService: ClientesService,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.cliente = this.data.cliente;
  }

  obtenerKey() {
    const valido = this.helper.deveui_valido(this.deveui);
    if (valido === true) {
      this.showSpinner = true;
      this.clientesService.obtenerAppKey(this.cliente._id, this.deveui).subscribe(
        (resp) => {
          this.key = resp.appkey;
          this.showSpinner = false;
        },
        (error) => {
          console.error(error);
          this.notificationsService.error('Error', error.error.mensaje);
          this.showSpinner = false;
        }
      );
    } else {
      this.notificationsService.alert('', valido);
    }
  }

  ngOnInit() {
  }

}
