import { Component, OnInit, Inject } from '@angular/core';
import { ICliente } from 'src/app/modelos/cliente';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IUsuario } from 'src/app/modelos/usuario';
import { ClientesService } from 'src/app/servicios/clientes.service';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { NotificationsService } from 'angular2-notifications';
import { AplicacionesService } from 'src/app/servicios/aplicaciones.service';
import { IAplicacion } from 'src/app/modelos/aplicacion';

@Component({
  selector: 'app-form-usuario',
  templateUrl: './form-usuario.component.html',
  styleUrls: ['./form-usuario.component.scss']
})
export class FormUsuarioComponent implements OnInit {

  clientes: ICliente[];
  aplicaciones: IAplicacion[];
  formulario: FormGroup;
  title: string;
  accion: string;
  roles = ['admin', 'user'];

  constructor(
    public matDialogRef: MatDialogRef<FormUsuarioComponent>,
    private formBuilder: FormBuilder,
    private clientesService: ClientesService,
    private aplicacionesService: AplicacionesService,
    private usuariosService: UsuariosService,
    private notif: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
  ) {
    this.crearFormulario();
  }

  private crearFormulario() {
    console.log(this.data);
    if (this.data) {
      this.title = 'Editar Usuarios';
      const idAplicaciones = [];
      for (const aplicacion of this.data.aplicaciones) {
        idAplicaciones.push(aplicacion._id);
      }
      this.formulario = this.formBuilder.group({
        _id: [this.data._id, Validators.required],
        id_cliente: [this.data.cliente._id, Validators.required],
        apellido: [this.data.apellido, Validators.required],
        nombre: [this.data.nombre, Validators.required],
        email: [this.data.email, Validators.required],
        rol: [this.data.rol, Validators.required],
        idAplicaciones: [idAplicaciones]
      });
    } else {
      this.title = 'Crear Usuario';
      this.formulario = this.formBuilder.group({
        id_cliente: ['', Validators.required],
        apellido: ['', Validators.required],
        nombre: ['', Validators.required],
        clave: ['', Validators.required],
        email: ['', Validators.required],
        rol: [null, Validators.required],
        idAplicaciones: [[]]
      });
    }
  }

  private listarAplicaciones() {
    this.aplicacionesService.listar().subscribe(
      (resp) => {
        this.aplicaciones = resp;
      },
      (error) => {
        console.error(error);
        this.notif.error(error.error.mensaje);
      }
    );
  }

  private listarClientes() {
    this.clientesService.listarClientes().subscribe(
      (resp) => {
        this.clientes = resp;
      },
      (error) => {
        console.error(error);
        this.notif.error(error.error.mensaje);
      }
    );
  }

  public enviar() {
    // this.showSpinner = true;
    const usuario: IUsuario = {
      _id: this.data ? this.data._id : null,
      apellido: this.formulario.value.apellido,
      idAplicaciones: this.formulario.value.idAplicaciones,
      id_cliente: this.formulario.value.id_cliente,
      nombre: this.formulario.value.nombre,
      clave: this.formulario.value.clave,
      email: this.formulario.value.email,
      rol: this.formulario.value.rol,
    };
    if (this.data) {
      this.usuariosService.editar(usuario).subscribe(
        (resp) => {
          this.notif.success(resp.mensaje);
          this.matDialogRef.close(true);
        },
        (error) => {
          // this.showSpinner = false;
          console.error(error);
          this.notif.error(error.error.mensaje);
        }
      );
    } else {
      this.usuariosService.crear(usuario).subscribe(
        (resp) => {
          this.notif.success(resp.mensaje);
          this.matDialogRef.close(true);
        },
        (error) => {
          // this.showSpinner = false;
          console.error(error);
          this.notif.error(error.error.mensaje);
        }
      );
    }

  }

  ngOnInit() {
    this.listarAplicaciones();
    this.listarClientes();
  }

}
