import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';
import { IUsuario } from 'src/app/modelos/usuario';
import { UsuariosService } from 'src/app/servicios/usuarios.service';
import { FormGroup } from '@angular/forms';
import { FormUsuarioComponent } from './form-usuario/form-usuario.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  displayedColumns: string[] = ['index', 'id', 'nombre', 'email', 'rol', 'activo', 'cliente', 'aplicaciones', 'acciones'];
  dataSource: MatTableDataSource<IUsuario>;
  showSpinner = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private usuariosService: UsuariosService,
    private notiticationsService: NotificationsService,
    public matDialog: MatDialog,

  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  private listar() {
    this.usuariosService.listar().subscribe(
      (resp) => {
        console.log(resp);
        this.dataSource = new MatTableDataSource(resp);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.showSpinner = false;
      },
      (error) => {
        console.error(error);
        this.notiticationsService.error(error.error.mensaje);
        this.showSpinner = false;
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  desactivar(id: string) {
    alert('desactivar: ' + id);
    // todo
  }

  activar(id: string) {
    alert('activar: ' + id);
    // todo
  }
  async eliminar(usuario: IUsuario) {
    const confirmed = confirm('Eliminar el usuario?');
    if (confirmed) {
      this.showSpinner = true;
      this.usuariosService.eliminar(usuario._id).subscribe(
        (resp) => {
          console.log(resp);
          this.notiticationsService.success(resp.mensaje);
          this.listar();
        },
        (error) => {
          console.error(error);
          this.notiticationsService.error(error.error.mensaje);
          this.showSpinner = false;
        }
      );
    }
  }
  // Crear - Modificar
  formUsuario(usuario?: IUsuario) {
    const config: MatDialogConfig = {
      width: '500px',
      maxWidth: '90%',
      panelClass: 'client-form-dialog',
      data: usuario ? usuario : null,
    };
    const modal = this.matDialog.open(FormUsuarioComponent, config);

    modal.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.showSpinner = true;
          this.listar();
        }
      }
    );
  }

  ngOnInit() {
    this.listar();
  }

}
