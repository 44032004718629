import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modulos/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Notifications
import { SimpleNotificationsModule } from 'angular2-notifications';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './componentes/login/login.component';
import { HomeComponent } from './componentes/home/home.component';
import { ClientesComponent } from './componentes/clientes/clientes.component';
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { FormClienteComponent } from './componentes/clientes/form-cliente/form-cliente.component';
import { ObtenerKeyComponent } from './componentes/clientes/obtener-key/obtener-key.component';
import { HexaDirective } from './directivas/hexa.directive';
import { UppercaseDirective } from './directivas/uppercase.directive';
import { FormUsuarioComponent } from './componentes/usuarios/form-usuario/form-usuario.component';
import { AplicacionesComponent } from './componentes/aplicaciones/aplicaciones.component';
import { CrearEditarAplicacionComponent } from './componentes/aplicaciones/crear-editar-aplicacion/crear-editar-aplicacion.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    ClientesComponent,
    UsuariosComponent,
    FormClienteComponent,
    ObtenerKeyComponent,
    HexaDirective,
    UppercaseDirective,
    FormUsuarioComponent,
    AplicacionesComponent,
    CrearEditarAplicacionComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    FormClienteComponent,
    FormUsuarioComponent,
    ObtenerKeyComponent,
    CrearEditarAplicacionComponent
  ]
})
export class AppModule { }
