import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './componentes/login/login.component';
import { HomeComponent } from './componentes/home/home.component';
import { LoggedGuard } from './guards/logged.guard';
import { ClientesComponent } from './componentes/clientes/clientes.component';
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { AplicacionesComponent } from './componentes/aplicaciones/aplicaciones.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [LoggedGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'clientes', component: ClientesComponent, canActivate: [LoggedGuard]},
  { path: 'usuarios', component: UsuariosComponent, canActivate: [LoggedGuard]},
  { path: 'aplicaciones', component: AplicacionesComponent, canActivate: [LoggedGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
