import { Directive, HostListener, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[appUppercase]',
    providers: [NgModel]
})
export class UppercaseDirective {

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

    constructor(private el: ElementRef) { }

    @HostListener('keyup') onKeyUp() {
        const new_value = this.el.nativeElement.value.toUpperCase();
        this.el.nativeElement.value = new_value;
        this.ngModelChange.emit(new_value);
    }

    @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
        const new_value = this.el.nativeElement.value.toUpperCase();
        this.el.nativeElement.value = new_value;
        this.ngModelChange.emit(new_value);
    }

}
