import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ICliente } from '../modelos/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private http: HttpService
  ) { }

  listarClientes(): Observable<ICliente[]> {
    return this.http.get('/clientes');
  }

  obtenerAppKey(id_cliente: string, deveui: string): Observable<any> {
    return this.http.get('/clientes/appkey/' + id_cliente + '/' + deveui);
  }

  crearCliente(cliente: ICliente): Observable<any> {
    return this.http.post('/clientes', cliente);
  }

  editar(cliente: ICliente): Observable<any> {
    return this.http.put('/clientes/' + cliente._id, cliente);
  }

  eliminar(id: string): Observable<any> {
    return this.http.del('/clientes/' + id);
  }

  desactivarCliente(id: number): Observable<any> {
    return this.http.put('/clientes/' + id + '/desactivar', {});
  }

  activarCliente(id: number): Observable<any> {
    return this.http.put('/clientes/' + id + '/activar', {});
  }
}
